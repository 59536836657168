import { rxAlerts, eventEmiter } from '../rxState';

export const addAlert = (state, payload) => {
  let alerts = [...state.alerts];
  alerts.push(payload);
  rxAlerts.next(alerts);

  setTimeout(() => {
    eventEmiter.next({
      type: 'remove-alert',
    });
  }, 3000);
};

export const removeAlert = (state) => {
  let alerts = [...state.alerts];
  alerts = alerts.length > 0 ? alerts.slice(1) : alerts;
  rxAlerts.next(alerts);
};
