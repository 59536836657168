import React from 'react';
import styles from './ChapterPlaceholders.module.scss';

const ChapterPlaceholders = () => {
  return (
    <main className={styles.placeholderWrapper}>
      <div className={styles.placeholderItemWrapper}>
        <div className={styles.placeholderCircle} />
        <div className={styles.placeholderLine} />
      </div>
      <div className={styles.placeholderItemWrapper}>
        <div className={styles.placeholderCircle} />
        <div className={styles.placeholderTwoLineWrapper}>
          <div className={styles.placeholderLine} />
          <div className={styles.placeholderLine} />
        </div>
      </div>
      <div className={styles.placeholderItemWrapper}>
        <div className={styles.placeholderCircle} />
        <div className={styles.placeholderLine} />
      </div>
      <div className={styles.placeholderItemWrapper}>
        <div className={styles.placeholderCircle} />
        <div className={styles.placeholderTwoLineWrapper}>
          <div className={styles.placeholderLine} />
          <div className={styles.placeholderLine} />
        </div>
      </div>
    </main>
  );
};

export default ChapterPlaceholders;
