export const DELETE_CHAPTER_MUTATION = {
  operationName: 'deleteChapter',
  query: `mutation deleteChapter(
      <KEYS>
    ) {
      deleteChapter(
        <VALUES>
      ) {
        message
      }
    }`,
};
