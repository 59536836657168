import { useEffect } from 'react';
import { CONSULT_URL } from 'Constants';

import s from './Popup.module.scss';

interface IProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

const UpdatePlanPopup = (props: IProps) => {
  useEffect(() => {
    window.addEventListener('message', (event) => {
      const data = event.data;
      if (data.status && data.status == 'close') {
        props.onClose();
      }
    });
  }, []);

  const iframeSrc = `${CONSULT_URL}/console/frame/update-plan?message=${encodeURIComponent(
    props.message
  )}`;

  if (!props.open) return null;

  return (
    <div className={s.frameModalPayment}>
      <iframe
        src={iframeSrc}
        title="update-plan"
        width="100%"
        height="100%"
        className={s.frame}
        allowTransparency={true}
        style={{ backgroundColor: 'transparent' }}
      ></iframe>
    </div>
  );
};

export default UpdatePlanPopup;
