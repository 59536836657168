import {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  DetailedHTMLProps,
} from 'react';
import clsx from 'clsx';

import styles from './AutoExpandTextarea.module.scss';

interface IProps
  extends Omit<
    DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'onChange'
  > {
  onChange: (value: string) => void;
  value?: string;
  theme: 'light' | 'dark';
}

const AutoExpandTextarea = ({ theme, value, onChange }: IProps) => {
  const [text, setText] = useState(value);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [text]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setText(newValue);
    onChange(newValue);
  };

  return (
    <textarea
      ref={textareaRef}
      className={clsx(styles.textarea, {
        [styles.lightMode]: theme === 'light',
        [styles.darkMode]: theme === 'dark',
      })}
      value={text}
      onChange={handleChange}
    />
  );
};

export default AutoExpandTextarea;
