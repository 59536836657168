import React, {useState, useEffect} from 'react';
import { ButtonDef } from './ToolbarManipulator';
import s from './ToolbarManipulatorButton.module.scss';
import clsx from 'clsx';

const ToolbarManipulatorButton = (props: ButtonDef) => {
    const handleMouseDown = (e:any) => {
        e.preventDefault();
        if(props.onMouseDown){
            props.onMouseDown(props.type, e);
        }
    }

    const handleMouseUp = (e:any) => {
        e.preventDefault();
        if(props.onClick){
            props.onClick(props.type);
        }
    }

    return (
        <div 
            className={ clsx(s.button, {[s.subButton]:props.submenu, [s.adminMode]:props.adminMode})} 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp}
            style={{ pointerEvents: props.enabled ? 'auto':'none' }}
            >
            <div className={s.inside}>
                <img className={s.icon} draggable={false} src={props.image} />
                {
                    props.submenu && 
                    <div className={s.name}>{props.name}</div> 
                }
            </div>
        </div>
    )
}

export default ToolbarManipulatorButton;