import LoadingSpinner from 'Components/Common/LoadingSpinner/LoadingSpinner';
import React, { useState, useRef, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import ChapterItem from '../ChapterItem/ChapterItem';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import s from './BookInfoBlock.module.scss';
import { ReactComponent as AddNewChapter } from '../../../Assets/addNewChapter.svg';
import { ReactComponent as Premium } from '../../../Assets/Premium.svg';
import Button from 'Components/Common/Button/Button';
import clsx from 'clsx';
import ChapterPlaceholders from '../ChapterPlaceholders/ChapterPlaceholders';
import { UserInfo } from '../../../Classes/UserInfo';
import UpdatePlanPopup from '../../Popups/UpdatePlanPopup';

const SMALL_BOOK_PRICE = 1;
const MEDIUM_BOOK_PRICE = 2;
const LARGE_BOOK_PRICE = 3;

interface IProps {
  bookTitle: string;
  onBookTitleEdited: (value: string) => void;
  onChapterTitleEdited: (value: string, index: number) => void;
  chapters: IBookChapter[];
  loadingChapter: boolean;
  loadingAddChapter: boolean;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  reorderChapter: (dragIndex: number, dropIndex: number) => void;
  onNoteAdd: (chapterIndex: number, noteText: string) => void;
  onGenerateBook: (value: string) => void;
  // setBookSize: (value: string) => void;
}

function BookInfoBlock({
  bookTitle,
  onBookTitleEdited,
  chapters,
  loadingChapter,
  onChapterTitleEdited,
  deleteChapter,
  onChapterAdd,
  reorderChapter,
  loadingAddChapter,
  onNoteAdd,
  onGenerateBook,
  // setBookSize,
}: IProps) {
  const [bookTitleInput, setBookTitleInput] = useState<string>(bookTitle);
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const [isBookTitleActive, setIsBookTitleActive] = useState<boolean>(false);
  const [isBookTitleLongError, setIsBookTitleLongError] = useState<boolean>(false);

  const [scopes, setScopes] = useState<string[]>([]);
  const [aiCredits, setAiCredits] = useState<number>(0);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const [upgradeReason, setUpgradeReason] = useState('');

  const [legacyAccessMode, setLegacyAccessMode] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastSortingTimestamp, setLastSortingTimestamp] = useState<
    number | null
  >(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

  const toggleDropdown = (index: number) => {
    if (openDropdownIndex === index) {
      setOpenDropdownIndex(null);
    } else {
      setOpenDropdownIndex(index);
    }
  };

  useEffect(()=>{
    fetchUserScope();
  }, [])

  
  useEffect(() => {
    setBookTitleInput(bookTitle);
  }, [bookTitle]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (lastSortingTimestamp && currentTime - lastSortingTimestamp > 1000) {
        //endReorderChapter();
      }
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [lastSortingTimestamp]);

  const fetchUserScope = async () => {
    const userInfo = await UserInfo.getInstance().getInfo();
    setScopes(userInfo.scopes);
    setAiCredits(userInfo.aiCredits);
    setLegacyAccessMode(!userInfo.scopes.includes('BOOKLE_GENERAL_ACCESS'))
  }
  const handleUpgradeClose = ()=>{
    setShowUpgrade( false );
    fetchUserScope();
  }

  const handleChapterAdding = (indexBefore: number, action: 'add' | 'insert')=>{
    if(!scopes.includes('BOOKLE_ADD_NEW_CHAPTER') && legacyAccessMode === false){ 
      showUpgradePlan('Adding new chapters are not available with your plan');
      return;
    }
    onChapterAdd(indexBefore, action)
  }

  const handleGenerateBook = (size: string)=>{
    setUpgradeReason('');
    if(legacyAccessMode){
      onGenerateBook(size);
      return;
    }

    if(size === 'MEDIUM'){
      if(!scopes.includes('BOOKLE_MEDIUM_BOOK')){ 
        showUpgradePlan('Medium books are not available with your plan');
        return;
      }
      if(aiCredits < MEDIUM_BOOK_PRICE){
        showUpgradePlan('Not enough credits to generate medium book');
        return;
      }
      
    }
    if(size === 'LARGE'){
      if(!scopes.includes('BOOKLE_LARGE_BOOK')){ 
        showUpgradePlan('Large books are not available with your plan');
        return;
      }
      if(aiCredits < LARGE_BOOK_PRICE){
        showUpgradePlan('Not enough credits to generate large book');
        return;
      }
    }

    if(size === 'SMALL' && aiCredits < SMALL_BOOK_PRICE){
      showUpgradePlan('Not enough credits to generate small book');
      return;
    }

    onGenerateBook(size);
  }

  const showUpgradePlan = (reason: string)=>{
    setUpgradeReason(reason);
    setShowUpgrade( true );
  }

  const handleNameInput = (value: string) => {
    let textValue = value
    if (textValue.length > 150) {
      textValue = textValue.slice(0, 150);
      setIsBookTitleLongError(true)
    } else{
      setIsBookTitleLongError(false)
    }
      const words = textValue.split(" "); 
      const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
          return word[0].toUpperCase() + word.slice(1); 
        }
        return word;
      });
      const capitalizedValue = capitalizedWords.join(" "); 
    
      setBookTitleInput(capitalizedValue);
    };

  const handleChapterSorting = (res: DropResult) => {
    const { destination, source, draggableId } = res;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    setLastSortingTimestamp(Date.now());
    reorderChapter(source.index, destination.index);
  };

  return (
    <div className={s.bookInfoContainer}>
      <div className={s.bookRelativeInfoContainer}>
      <DragDropContext onDragEnd={handleChapterSorting}>
      <div className={s.bookInfoEditNameInput}>
        <div className={s.bookInfoEditNameInputHeader}>Your Book Title</div>
        <div className={s.bookInfoEditNameInputInner}>
          <ContentEditableDiv
            name={bookTitleInput}
            fontSize={30}
            lineHeight={42}
            fontWeight={600}
            width={500}
            height={37}
            onChange={handleNameInput}
            onEdit={onBookTitleEdited}
          />
          {isBookTitleLongError && <div className={s.errorText}>Maximum characters 150</div>}

        </div>
      </div>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={s.chaptersBlock}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {loadingChapter ? (
                <ChapterPlaceholders />
              ) : (
                chapters.map((chapter: IBookChapter, index) => (
                  <Draggable
                    key={chapter.title + index.toString()}
                    draggableId={`chapter-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ChapterItem
                          addChaptersLocked={!scopes.includes('BOOKLE_ADD_NEW_CHAPTER') && !legacyAccessMode}
                          index={index}
                          chapter={chapter}
                          onChapterTitleEditedProps={onChapterTitleEdited}
                          activeChapter={activeChapter}
                          setActiveChapter={setActiveChapter}
                          deleteChapter={deleteChapter}
                          onChapterAdd={handleChapterAdding}
                          loadingAddChapter={loadingAddChapter}
                          onNoteAdd={onNoteAdd}
                          isBookTitleActive={isBookTitleActive}
                          setIsBookTitleActive={setIsBookTitleActive}
                          toggleDropdown={toggleDropdown}
                          isOpen={openDropdownIndex === index}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              )}
              {!loadingChapter && (
                <div
                  className={s.addNewChapter}
                  onClick={() => handleChapterAdding(chapters.length, 'add')}
                >
                  
                  {loadingAddChapter ? (
                    <LoadingSpinner />
                  ) : (
                    <div style={{position:'relative', display:'flex'}}>
                      {!scopes.includes('BOOKLE_ADD_NEW_CHAPTER') && !legacyAccessMode ? <Premium  className={s.premiumIconWithOffset}/> : null}
                      <AddNewChapter />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      </div>
      {!loadingChapter && (
        <div className={s.generateBookButtonsBlock}>
          {!legacyAccessMode &&           
            <div className={s.credits}>
              Credits: {aiCredits}
            </div>
          }
          
          <Button
            size="regular"
            color="accent"
            onClick={() => handleGenerateBook("SMALL")}
            borderRadius={'25px'}
          >
            <div className={s.generateBookButtonsContainer}>
              <div className={s.generateBookButtonsTextBlock}>
                <span className={s.textSize}>Small</span>
              </div>
              {!legacyAccessMode && <span className={s.textPrice}>1 Credit</span> }
            </div>
          </Button>
          <Button
            size="regular"
            color="accent"
            onClick={() => handleGenerateBook("MEDIUM")}
            borderRadius={'25px'}
          >
            
            <div className={s.generateBookButtonsContainer}>
              <div className={s.generateBookButtonsTextBlock}>
               {!scopes.includes('BOOKLE_MEDIUM_BOOK') && !legacyAccessMode ? <span><Premium  className={s.buttonIcon}/></span> : null}
                <span className={s.textSize} >Medium</span>
              </div>
              {!legacyAccessMode && <span className={s.textPrice} >2 Credits</span> }
            </div>
          </Button>
          <Button
            size="regular"
            color="accent"
            onClick={() => handleGenerateBook("LARGE")}
            borderRadius={'25px'}
          >
            <div className={s.generateBookButtonsContainer}>
              <div className={s.generateBookButtonsTextBlock} >
                {!scopes.includes('BOOKLE_LARGE_BOOK') && !legacyAccessMode ? <span><Premium  className={s.buttonIcon}/></span> : null}
                <span className={s.textSize}>Large</span>                
              </div>
              {!legacyAccessMode && <span className={s.textPrice} >3 Credits</span> }
            </div>
          </Button>
        </div>
      )}
      <UpdatePlanPopup open={showUpgrade} onClose={handleUpgradeClose} message={upgradeReason}/>
    </div>
    
  );
}

export default BookInfoBlock;
