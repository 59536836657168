import { cloneDeep } from 'lodash';
import { rxSections } from 'rx/rxState';
import { Block, BlockType, Section } from 'types/page';
import { randomElement } from 'utils/functions';
import { IGookGeneratorPayload } from 'Components/books/types';
import { textParserToDraft } from '../../Utils';

const kTextSection = [
  'AI-BookChapter-1',
];

const kCoverSection = [
  'AI-BookCover-1',
]

const kBadgeSection = [
  'AI-BookBadge'
]

interface IUpdateTextOptions {
  skip?: number;
  updateFontFamelyTo?: string;
  aiField?: string;
}

const isSection = (b: any): b is Section => {
  return (b as Section).data !== undefined && (b as Section).name !== undefined;
};

interface IImageField {
  key: string;
  src?: string;
}

export const sectionFromBlockData = (data: Block): Section => {
  return {
    name: 'New',
    data,
  };
};

export class SectionController {
  section: Section;
  constructor(param: string[] | Section, mutable = false) {
    if (isSection(param)) {
      this.section = mutable ? param : cloneDeep(param);
      return;
    }
    const sections = rxSections.getValue() as Section[];
    const blocs = sections.filter((section) => param.includes(section.name));
    let data = blocs[Math.floor(Math.random() * blocs.length)];
    this.section = mutable ? data : cloneDeep(data);
  }

  get data() {
    return this.section.data;
  }

  public find(type: BlockType, skip = 0, isVisible = false): Block | undefined {
    const visibleFilter = !isVisible || this.section.data.isVisible !== false;
    if (type === this.section.data.type && !skip && visibleFilter) {
      return this.section.data;
    }
    if (type === this.section.data.type && skip > 0 && visibleFilter) {
      skip--;
    }
    return this.recursiveSearch(this.section.data, type, [skip], isVisible);
  }

  //Used SKIP as array so it will be passed as reference and not by value. since if we going in to different branch of Tree Skip will be goes to roginal value
  public recursiveSearch(
    element: Block,
    type: BlockType,
    skip = [0],
    isVisible = false
  ): Block | undefined {
    for (const child of element.children || []) {
      const visibleFilter = !isVisible || child.isVisible !== false;
      if (type == child.type && !skip[0] && visibleFilter) {
        return child;
      }
      if (type == child.type && skip[0] > 0 && visibleFilter) {
        skip[0]--;
      }
      if (child.children && child.children.length) {
        const found = this.recursiveSearch(child, type, skip, isVisible);
        if (found) {
          return found;
        }
      }
    }
  }

  hideButton(skip = 0) {
    const element = this.find('Button', skip);
    if (element) {
      element.isVisible = false;
    }
  }

  updateText(
    newText: string,
    { skip = 0, aiField, updateFontFamelyTo }: IUpdateTextOptions
  ): SectionController {
    let textBlock = this.find('Text', skip);
    if (!textBlock) {
      textBlock = this.find('TextCover', skip);
      if (!textBlock) {
        return this;
      }
    }

    const blocks = textBlock.text!.value.blocks[0];

    const parsedBlocks = textParserToDraft(newText,  blocks.inlineStyleRanges, blocks.type);
    textBlock.text!.value = parsedBlocks as any;

    // textBlock.text!.value.blocks = [
    //   {
    //     ...blocks,
    //     text: newText,
    //     inlineStyleRanges: blocks.inlineStyleRanges
    //       .filter((style) => style.offset == 0)
    //       .map((style) => ({
    //         ...style,
    //         length: newText.length,
    //       })),
    //   },
    // ];


    if (!newText.trim()) {
      textBlock.isVisible = false;
    }
    textBlock.aiField = aiField;
    return this;
  }

  updateReRollText(
    newText: string, textBlock: Block
  ) {
    const blocks = textBlock.text!.value.blocks[0];
    textBlock.text!.value.blocks = [
      {
        ...blocks,
        text: newText,
        inlineStyleRanges: blocks.inlineStyleRanges
          .filter((style) => style.offset == 0)
          .map((style) => ({
            ...style,
            length: newText.length,
          })),
      },
    ];

    if (!newText.trim()) {
      textBlock.isVisible = false;
    }
    return textBlock.text!.value;
  }

  static updateBackground(blocks: Block[], image: IImageField, deep = 0) {
    for (const child of blocks || []) {
      if ('Section' === child.type) {
        child.image = image.src;
      }
      if (child.children && deep > 0) {
        this.updateBackground(child.children, image, deep - 1);
      }
    }
  }

  fontFamilyFontFamily(): string | undefined {
    const textBlock = this.find('Text', 0);
    if (!textBlock) {
      return;
    }
    const blocks = textBlock.text!.value.blocks[0];
    return blocks.inlineStyleRanges.find((style) =>
      style.style.startsWith('font-')
    )?.style;
  }

  updateImage(newSrc: string, field: string, skip = 0): SectionController {
    const element = this.find('Image', skip, true);
    if (element) {
      element.aiField = field;
      element.src = newSrc;
    }
    return this;
  }
}

const fontFamelys = {
  headline: ['Anton', 'BebasNeue', 'Oswald', 'Montserrat', 'Poppins', 'PTSans'],
  paragraph: [
    'Raleway',
    'Roboto',
    'Lato',
    'AverageSans',
    'Montserrat',
    'Poppins',
    'PTSans',
  ],
};

export class AIBookGenerator {
  fontFamely?: string;
  fontFamelyHeader?: string;

  data: Block[] = [];
  lastId = 0;

  suggestionImages: IImageField[] = [];

  constructor() { }

  addTextBlock(title: string, textBody: string, bookName: string) {
    const textBlock = new SectionController(kTextSection);

    textBlock
      .updateText(
        bookName,
        {
          aiField: 'title',
          updateFontFamelyTo: this.fontFamelyHeader,
        }
      )
      .updateText(
        title,
        {
          aiField: `paragraph`,
          skip: 1,
        }
      )
      .updateText(
        textBody,
        {
          aiField: `paragraph`,
          skip: 2,
        }
      )

    this.data.push(textBlock.data);
  }

 createTextBlock(title: string, textBody: string, bookName: string) {
    const textBlock = new SectionController(kTextSection);

    textBlock
      .updateText(
        bookName,
        {
          aiField: 'title',
          updateFontFamelyTo: this.fontFamelyHeader,
        }
      )
      .updateText(
        title,
        {
          aiField: `paragraph`,
          skip: 1,
        }
      )
      .updateText(
        textBody,
        {
          aiField: `paragraph`,
          skip: 2,
        }
      )

    return textBlock.data;
  }

  createBlankPage (){        
    
    const coverBlock = new SectionController(kCoverSection);
    coverBlock.section.data.children = [];
    
    coverBlock.section.data['color'] = '#808080';
    return coverBlock.data
  }

  createInitialData (bookTitle: string, coverUrl?: string) {
    const coverBlock = new SectionController(kCoverSection);
    coverBlock.section.data['color'] = '#808080';
    if (coverUrl) {
      coverBlock.section.data['image'] = coverUrl;
    }
    coverBlock.updateText(bookTitle, {});
    this.lastId = this.reIndexBlocks(1);

    return {
      blocks: [coverBlock.section.data],
      lastId: this.lastId
    }
  }
  
  generate(options: IGookGeneratorPayload) {
    const coverUrl = options.coverUrl || null;
    const bookTitle = options.bookTitle || null;
    const coverBlock = new SectionController(kCoverSection);
    coverBlock.section.data['image'] = coverUrl;
    coverBlock.section.data['color'] = '#808080';

    coverBlock.updateText(bookTitle!, {})
    this.data.push(coverBlock.data);

    this.lastId = this.reIndexBlocks(1);

    options.chapters.forEach(chapter => {
      this.fontFamely = 'font-' + randomElement(fontFamelys.paragraph);
      this.fontFamelyHeader = 'font-' + randomElement(fontFamelys.headline);

      this.addTextBlock(chapter.title, chapter.chapter, chapter.bookName);

      this.lastId = this.reIndexBlocks(1);
    })


    return {
      blocks: this.data,
      lastId: this.lastId,
    };
  }

  static build(options: IGookGeneratorPayload) {
    const instance = new this();
    return instance.generate(options);
  }

  reIndexBlocks(index = 1, children: Block[] = this.data) {
    for (const child of children || []) {
      child.id = index++;
      if (child.children && child.children.length) {
        index = this.reIndexBlocks(index, child.children);
      }
    }
    return index;
  }

  shuffle(array: Array<string>) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
}
