import React, { Fragment } from 'react';
import styled from 'styled-components';
import {Block, BlockStyle} from './Block';
import { ReactComponent as ImagePlaceholderIcon } from '../../Assets/BlockPlaceholderIcons/image.svg';
import { PAGECRAFT_API_URL } from '../../Constants';
import StoreController from 'StoreController';
import { generateAiImageBySocket } from 'utils/socket';
import { rxRerollImageData } from 'rx/rxState';
import BlockProcessing from 'Components/BlockProcessing/BlockProcessing';

const StyledImage = styled.div`
    user-select: none;
    width: 100%;
    ${props=>props.imageFit == 'cover'?'height: 100%;' : null}
    overflow: hidden;
    pointer-events: auto;
    .content {
        object-fit: ${props=>props.imageFit};
        width:100%;
        height: 100%;
    }

    .placeholder {
        padding: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >svg{
            margin: 2px;
            width: 100%;
            height: 100%;
        }
    }
`;


const BlockImageStyle = styled(BlockStyle)`
    display: flex;
    cursor:${props=>props.cursor};
    align-items: ${props=>props.verticalAlign}
`;

export class BlockImageProxy extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            url: props.content,
            fileMenuOpened: false
        }
        this.inputRef = React.createRef();
    }
    componentDidMount(){
        this.props.reference.current.style.cssText = this.props.styleText();
    }
    componentDidUpdate(prevProps){
        // if(prevProps.styleText !== this.props.styleText){
            this.props.reference.current.style.cssText = this.props.styleText();
        // }
    }
    handleGetFile(file) {
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onloadend = ((res) => {
            const content = reader.result;
            this.setState({
                ...this.state,
                url: content
            });

        });

        this.uploadImage( file );

    }

    uploadImage(file){
        const formData  = new FormData();
        formData.append("field", JSON.stringify({ id: '5fe2fa7753336419b079ae2f'}));
        formData.append("file", file);

        fetch(`${PAGECRAFT_API_URL}/images`, {
            method: "POST",
            body: formData
        })
        .then(
            r => r.json()
        )
        .then( data => {
            this.setState({
                ...this.state,
                url: null
            });
            this.props.setImage(data.url);
            this.forceUpdate();
        })
    }

    onDrop(event){
        event.preventDefault();
        event.stopPropagation();

        let files = event.dataTransfer.files;
        let file = files[0];

        if (file) {
            this.handleGetFile(file);
        }
    }

    loadFile(e) {
        this.props.setLoadClosed();
        const files = this.inputRef.current.files
        let file = files[0];
        if (file) {
            this.handleGetFile(file);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.openLoadFile !== this.props.openLoadFile && nextProps.openLoadFile) {
            this.inputRef.current.click();
            this.props.setLoadClosed();
        }
    }

    async onClick() {
        if (StoreController.instance().liveMode === false) {
            return;
        }
        if (this.props.destination.value == 'URL') {
            if (this.props.url.value) {
                let url = this.props.url.value;
                if (this.props.url.value.indexOf('https://') === -1 && this.props.url.value.indexOf('http://') === -1) {
                    url = 'https://' + this.props.url.value;
                }
                window.open(url, '_blank');
            }
        }
    }

    render(){
        return <BlockImageStyle
            id={this.props.id}
            key={this.props.id}
            style={this.props.style}
            width={this.props.width}
            ref={this.props.reference}
            verticalAlign={this.props.verticalAlign}
            className={this.props.className}
            onClick={this.onClick.bind(this)}
            cursor={(this.props.destination.value == 'URL' && StoreController.instance().liveMode === true) ? 'pointer' : 'auto'}
            >
                <div style={{position:'relative', width: '100%'}}>
                    <StyledImage
                        imageFit={this.props.imageFit}
                        onDrop={(e)=>this.onDrop(e)}
                    >
                        {(this.state.url || this.props.imageUrl) ?
                            <img
                            src={ this.state.url || this.props.imageUrl}
                            className="content"
                            alt=''
                            draggable="false"
                            ></img>

                            :
                            <div
                                className="placeholder"
                            >
                                <ImagePlaceholderIcon />
                            </div>
                        }
                    </StyledImage>
                    {this.props.children}
                    <input
                        type="file"
                        style={{display: 'none'}}
                        accept="image/*"
                        ref={this.inputRef}
                        onChange={(e) => this.loadFile(e)}
                    />
                    {this.props.processing && <BlockProcessing/>}
                </div>
            </BlockImageStyle>
        }
}

export class BlockImage extends Block{
    constructor(props){
        super(props);
        this.type = 'Image';
        this.openLoad = false;
        this.processing = false;

        let attr = {
            id: 'imageFit',
            displayName: 'Image Fit',
            value: 'contain',
            type: 'AttributeDropdown',
            options: [
                {
                    label: 'Contain',
                    value: 'contain'
                },
                {
                    label: 'Cover',
                    value: 'cover'
                },
                {
                    label: 'None',
                    value: 'none'
                }
            ],
        }
        this.addAttribute( attr );

        attr = {
            id: 'horizontalAlign',
            displayName: 'Image Align',
            value: 'center',
            type: 'AttributeDropdown',
            options: [
                {
                    label: 'Top',
                    value: 'flex-start'
                },
                {
                    label: 'Center',
                    value: 'center'
                },
                {
                    label: 'Bottom',
                    value: 'flex-end'
                }
            ],
        }
        this.addAttribute( attr );

        attr = {
            id: 'destination',
            displayName: 'Destination',
            value: 'No',
            type: 'AttributeDropdown',
            options: [{
                label: 'No',
                value: 'No'
            },
            {
                label: 'URL',
                value: 'URL'
            },],
        }
        this.addAttribute(attr);

        attr = {
            id: 'url',
            displayName: '',
            value: '',
            width: 200,
            prefix: '',
            type: 'AttributeString',
            visible: false
        }
        this.addAttribute(attr);

        attr = {
            id: 'imageUrl',
            displayName: 'Image',
            visible:true,
            type: 'AttributeImage',
        }
        this.addAttribute( attr );
    }

    setImage(img){
        this.imageUrl.value = img;
        this.view.update();
    }

    pack(){
        let data = super.pack();
        return data;
    }
    unpack(data){
        super.unpack(data);

        let image = data['src'];
        if(image !== undefined){
            this.imageUrl.value = image;
        }

        let imageFit = data['imageFit'];
        if(typeof imageFit === 'string'){
            this.imageFit.value = imageFit;
        }
    }

    setEditing( value) {
        super.setEditing( value );
        if (value) {
            this.openLoad = true;
        }
    }

    handleClose() {
        this.openLoad = false;
    }

    async rerollWithAiPrompt(){
        const prompt = this.getAiFieldPrompt();
        if(prompt){
            this.processing = true;
            const ratio = '1:1';
            const payload = {
                text: prompt,
                imageCount: 1,
                ratio
            }

            const response = await generateAiImageBySocket({
                payload
            })
            
            this.processing = false;
            this.setImage(response.imageUrl);

        }

    }
    reroll(){
        super.reroll();

        rxRerollImageData.next({
            block:this
        })
    }


    renderView(){
        if (this.destination.value === 'URL') {
            this.url.visible = true;
        } else{
            this.url.visible = false;
        }
        return (
            <Fragment key={this.id}>
            { this.isVisible ?
            <>
                {/* { this.isDragging ? this.getPlaceholder()
                : */}
                <BlockImageProxy
                    id={this.id}
                    key={this.id}
                    width={this.worldRenderBRect ? this.worldRenderBRect.width : 0}
                    setImage={(v) => this.setImage(v)}
                    reference={this.ref}
                    ref={this.proxyRef}
                    verticalAlign={this.horizontalAlign.value}
                    styleText={this.style}
                    imageFit={this.imageFit.value}
                    editingHandle={this.editingHandle.bind(this)}
                    openLoadFile={this.openLoad}
                    setLoadClosed={this.handleClose.bind(this)}
                    destination={this.destination}
                    url={this.url}
                    imageUrl={this.imageUrl.value}
                    className={this.className}
                    processing={this.processing}

                    >
                        {
                            this.children.map((child)=>{
                            return child.renderView();
                            })
                        }
                </BlockImageProxy>
                {/* } */}
            </>
            :
            null
            }
            </Fragment>
        )
    }
}
