import React from 'react';
import s from './Popup.module.scss';
import PopUpWrapper from './PopupWrapper';
import Button from '../Common/Button/Button';
import smileSvg from '../../Assets/smile.svg';

interface IProps {
  onClose: () => void;
  onExit: () => void;
  onSave: () => void;
  open: boolean;
}

const SaveWebSite = (props: IProps) => {
  return props.open ? (
    <PopUpWrapper onClose={props.onClose}>
      <div className={s.popupContent}>
        <img src={smileSvg} alt="smile" className={s.mainImage} />
        <h2 className={s.header}>Save your Website</h2>
        <div className={s.saveWebSiteText}>
          If you exit website builder, all content will be lost.
          <br />
          Please take a 1 minute of your time, just sign up and save your
          website!
        </div>
        <div className={s.buttonsBlock}>
          <Button onClick={() => props.onExit()} color="secondary">
            Exit and Lose
          </Button>
          <Button onClick={() => props.onSave()}>Sign Up and Save</Button>
        </div>
      </div>
    </PopUpWrapper>
  ) : null;
};

export default SaveWebSite;
