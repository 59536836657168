import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import AIQuestionnaire from 'Components/AIQuestionnaire/AIQuestionnaire';
import { useObservable } from 'utils/UseObservable';
import { rxIsLoading } from 'rx/rxState';
import Spinner from 'Components/Common/Spinner/Spinner';

const AlertStyle = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);

  .loading {
    margin-top: 6px;
    display: inline-block;
    width: 100%;
    height: calc(100% - 12px);
  }

  .spinner {
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

const AICreatePage = () => {
  const loading = useObservable(rxIsLoading);

  useEffect(() => {
    setTimeout(() => rxIsLoading.next(''), 500);
  }, []);

  return (
    <>
      {loading !== '' && loading !== 'Generate page...' && (
        <AlertStyle>
          <div className="loading">
            <div className="spinner">
              <Spinner size={110} />
              {loading}
            </div>
          </div>
        </AlertStyle>
      )}
      <AIQuestionnaire />
    </>
  );
};

export default AICreatePage;
