export const UPDATE_AI_PAGE = {
    operationName: 'updateAiPage',
    query: `mutation updateAiPage (\
        $pageId: String!,
        $data: String!
        ){
        updateAiPage(
            pageId: $pageId,
            data: $data
        ){
        _id
        questions {
          id
          label
          example
        }
        prompts {
          id
          type
          prompt
        }
        createdAt
        updatedAt
        pageId
      }
    }
    `
}