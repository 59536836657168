import { getUserId } from "./api";
import { getBySocket, createSocket } from "./socket";

export const getNewTextWithAI = async (text) => {
  if (!text) {
    return '';
  }

  const userId = getUserId();
  const payload = { text };

  if (userId) {
    payload.userId = userId;
  }

  const socket = createSocket();
  const response = await getBySocket({
    emitEventName: 'ai-page-regenerate',
    resultEventName: 'response-page-regenerate',
    payload,
    socket
  });
  return response.text;
}

export const getTextWithAiPrompt = async (text) => {
  if (!text) {
    return '';
  }

  const userId = getUserId();
  const payload = { text };

  if (userId) {
    payload.userId = userId;
  }

  const socket = createSocket();
  const response = await getBySocket({
    emitEventName: 'ai-single-prompt',
    resultEventName: 'response-ai-single-prompt',
    payload,
    socket
  });
  return response.result.text;
}


export function generateFingerPrint() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  const date = new Date();
  const timestamp = date.valueOf();
  const randomString = `${timestamp}-${result}`;
  localStorage.setItem("MechFingerprint", randomString);
  return randomString;
}