import { io, Socket } from 'socket.io-client';
import { SOCKET_URL, NO_SOCKET_CREDENTIALS } from '../Constants';
interface IGetSocketArgs {
  emitEventName: string;
  resultEventName: string;
  socket: Socket;
  payload: any;
}

interface IGetSocketImageArgs {
  payload: any;
  callback?: (progress:any) => void;
}

export const getBySocket = async ({ emitEventName, resultEventName, socket, payload }: IGetSocketArgs) => {
  return new Promise((resolve) => {
    socket.emit(emitEventName, payload);
    socket.on(resultEventName, (res: any) => {
      console.log('got response ', res)
      resolve(res)
    });
  });
}

export const generateAiImageBySocket = async (args: IGetSocketImageArgs) => {
  const { payload, callback } = args;
  return new Promise(resolve => {
    const socket = createSocket();
    socket.emit('generate-openai-image', payload);
    socket.on('open-ai-image-generated', data => {
      console.log('open ai image response', data);
      if (data.imageUrls?.length ) {
        resolve(data);
      } else if (callback) {
        callback({id: data.id, error: data.error});
      }
    })
  })
}

export const createSocket = () => {
  if (NO_SOCKET_CREDENTIALS) {
    return io(SOCKET_URL)
  }
  return io(SOCKET_URL, {
    withCredentials: true
  })
}

export const generateThumbnail = async (projectId: string) => {
  const content = document.getElementById('view');
  const html = content?.innerHTML;
  if (!html) {
    throw new Error('html content not found')
  }
  
  await getBySocket({
    emitEventName: 'create-page-thumbnail',
    resultEventName: 'page-thumbnail-created',
    socket: createSocket(),
    payload: {
      html,
      height: 600,
      width: 800,
      pageId: projectId
    }
  })
}

