export const REMOVE_FILES_FROM_S3 = {
  operationName: 'removeFilesFromS3',
  query: `mutation removeFilesFromS3(
      $urls: [String!]!
    ) {
      removeFilesFromS3(
        urls: $urls
      ) {
        message
      }
    }`,
};
