import { getToken } from '../../utils/api';
import { PAGECRAFT_API_URL } from '../../Constants';
import {
  rxStripeKey,
  rxIsLoading,
  rxCurrentPage,
  rxBlocks,
  rxBlocksFetched,
  rxEditMode,
  rxUserName,
  rxPageId,
  rxProducts,
  rxProjectName,
  rxPageName,
  rxProjectId,
  rxError,
  rxExitModalEnabled,
  rxExitModalData,
  rxCustomScripts,
  rxTitle,
} from '../rxState';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import { validateToken } from 'Utils';

const getNext = (pages, order, pageId) => {
  if (!pageId) {
    return null;
  }
  let next;
  let nextPage;
  let name;
  order.forEach(item => {
    if (item.id === pageId) {
      for (const pageName in pages) {
        const page = pages[pageName];
        if (item.next === page._id) {
          nextPage = page;
          name = pageName;
        }
      }

      if (nextPage && nextPage.enabled) {
        next = {
          id: item.next,
          name
        };
      }
    }
  });

  if (next) {
    return next;
  }
  if (nextPage) {
    return getNext(pages, order, nextPage._id);
  }

  return null;
}

const RESERVED_FIELDS = ['message', '_navigation', '_message', '_info'];
export const rxFetchPages = ({ username, id, editMode, page, applyBlocks = true }) => {
  console.log('---------editMode', editMode)
  const result = editMode ? fetchPageData(id) : loadLiveData(page, id);

  rxIsLoading.next('Loading initial data');
  result
    .then((data) => {

      if (!data) {
        data = { pages: {} }
      }
      if (!data.pages[page]) {
        data.pages[page] = [];
      }

      const pageData = data.pages[page];
      if (pageData.exitpopupenabled) {
        rxExitModalEnabled.next(pageData.exitpopupenabled);
      }

      if (pageData.exitpopupdata) {
        rxExitModalData.next(pageData.exitpopupdata);
      }

      rxCurrentPage.next(page);
      const productsValue = rxProducts.value;

      rxProducts.next(productsValue.concat( data.products ));

      console.log('FETCH DATA', data)
      rxStripeKey.next(data.stripePublicKey);

      if (applyBlocks) {
        rxBlocks.next(pageData);
      }

      rxBlocksFetched.next(true);
      rxEditMode.next(editMode);
    })
    .catch((err) => {
      rxError.next(true);
      console.log('FETCH ERROR', err)
      // window.open('/console/login', '_self');
    })
    .finally(() => {
      rxPageId.next(id);
      rxUserName.next(username);
      fetchPageInfo(id);
      rxIsLoading.next('');
    });
};

const fetchPageData = async (id) => {
  const data = await graphQlCall({
    queryTemplateObject: QUERIES.GET_PAGE_EDIT_INFO_QUERY,
    values: {
      id
    },
    headerType: 'USER-AUTH'
  });

  const subPages = {};
  data.pages.forEach(page => {
    subPages[page.name] = {
      ...page.data,
      _id: page._id,
      image: page.image,
      enabled: page.enabled,
      exitpopupdata: page.exitpopupdata,
      exitpopupenabled: page.exitpopupenabled,
    };
  });

  data.pages = subPages;

  return data;
};

const loadLiveData = async (pageName, pageId) => {
  console.log("load LIVE data", pageName);
  let data;
  if (document.getElementById('page-data') === null) {
    const itemName = `liveData-${pageId}-${pageName}`;
    const json = localStorage.getItem(itemName);
    console.log('LOCAL cache', JSON.parse(json));
    if (json == null) {
      console.log(`page: ${pageId} not found in cache, fetching`);
      const request = {
        action: 'load',
        id: pageId,
      };

      return await fetchPageData(pageId);
    }
    else {
      console.log('loading from local cache');
      data = { pages: {} };
      const page = JSON.parse(json);
      data.pages[pageName] = page;

      const modalPopupKey = `liveData-${pageId}-${pageName}-modal`;
      const modalJson = localStorage.getItem(modalPopupKey);
      if (modalJson) {
        console.log('loading popup data form local cahce');
        data.pages[pageName].exitpopupdata = JSON.parse(modalJson);
      }

      data.stripePublicKey = page.stripePublicKey;
      data.products = page.products;

      console.log('data', data);
      return data;
    }

  } else {
    const json = document.getElementById('page-data').innerHTML;
    data = { pages: {} };
    const page = JSON.parse(json);
    data.pages[pageName] = page;
    data.order = [{ id: page._id, parent_id: 0 }];
    data.stripePublicKey = page.stripePublicKey;
    data.products = page.products;

    return data;
  }
};

const fetchPageInfo = async (pageId) => {
  const data = await graphQlCall({
    queryTemplateObject: QUERIES.GET_PAGE_INFO_QUERY,
    values: {
      id: pageId
    }
  });

  rxProjectName.next(data.projectName);
  rxPageName.next(data.pageName);
  rxProjectId.next(data.projectId);

  const scripts = {
    footerScript: data.footerScript,
    funnelFooterScript: data.funnelFooterScript,
    funnelHeaderScript: data.funnelHeaderScript,
    headerScript: data.headerScript,
  }

  rxCustomScripts.next(scripts);
  rxTitle.next(data.title);
};

const getCache = () => {
  const data = localStorage.getItem('SHOMIO_CACHE');
  localStorage.removeItem('SHOMIO_CACHE');
  return data ? JSON.parse(data) : null;
}
