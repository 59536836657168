import React, { ReactNode } from 'react';
import Tooltip from 'Components/Common/Tooltip/Tooltip';

import styles from './StyleButton.module.scss';

interface IProps {
  style: string;
  onToggle: (style: string) => void;
  tooltip: string;
  active: boolean;
  icon: ReactNode;
}

const StyleButton = ({ active, icon, onToggle, style, tooltip }: IProps) => {
  const handleToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onToggle) {
      e.preventDefault();
      onToggle(style);
    }
  };

  return (
    <Tooltip text={tooltip}>
      <div
        className={active ? styles.activeButton : styles.button}
        onMouseDown={handleToggle}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

export default StyleButton;
