export const SET_COVER_IMAGE_MUTATION = {
  operationName: 'setCoverImage',
  query: `mutation setCoverImage(
      <KEYS>
    ) {
      setCoverImage(
        <VALUES>
      ) {
        coverImageUrl
      }
    }`,
};
