import styles from './Modal.module.scss';

interface IProps {
  closeModal?: () => void;
  displayModal: boolean;
  children: React.ReactElement[];
}

const Modal = ({ children, displayModal, closeModal }: IProps) => {
  const handleOutsideClick = (e?: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();

    if (closeModal) closeModal();
  };

  return (
    <div
      className={styles.modal}
      onClick={handleOutsideClick}
      style={{ display: displayModal ? 'block' : 'none' }}
    >
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
