import React, { useEffect, useState } from 'react';
import s from './Popup.module.scss';
import PopUpWrapper from './PopupWrapper';
import Button from '../Common/Button/Button';
import alertSvg from '../../Assets/Attention.svg';
import { useObservable } from 'utils/UseObservable';
import { rxBadContentAlert } from 'rx/rxState';


const InappropriateAIContentPopup = () => {
  const hasBadContent = useObservable(rxBadContentAlert);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (hasBadContent) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [hasBadContent]);

  const handleClose = () => {
    rxBadContentAlert.next(false);
    setOpen(false);
  }

  return open ? (
    <PopUpWrapper onClose={() => handleClose()}>
      <div className={s.popupContent}>
        <img src={alertSvg} alt="clock" className={s.mainImage} />
        <h2 className={s.header}>Inappropriate <br/> Content</h2>
        <div className={s.notAtAllText}>
          The content submitted in your prompt has been identified as potentially inappropriate or offensive to some parties. <br/>
          This goes against the AutoFunnel content policy. <br/>
          Please write a new prompt and try again
        </div>
        <div className={s.singleButtonBlock}>
          <Button onClick={() => handleClose()} color={'primary'} size='regular' borderRadius='20px'>
            Got it
          </Button>
        </div>
      </div>
    </PopUpWrapper>
  ) : null;
};

export default InappropriateAIContentPopup;
