import React, {useState, useEffect} from 'react';
import { Block } from 'Canvas/Blocks/Block';
import ToolbarManipulatorButton from './ToolbarManipulatorButton';
import ToolbarWidgetAiFeild from './ToolbarWidgetAiField';
import useElementClickedOutside from "Hooks/useElementClickedOutside";
import { kBlockReorderHandler } from 'Canvas/Manipulator';
import s from './ToolbarManipulator.module.scss';

import { eventEmiter, rxManipulatorInUse } from 'rx/rxState';
import { useObservable } from 'utils/UseObservable';
import { isAdmin } from '../../utils/api';

const TOP_SCREEN_THRESHOLD = 120;
const TOOLBAR_TOP_OFFSET = 55;
const TOOLBAR_BOTTOM_OFFSET = 15;

enum WidgetType {
    Null,
    AiField
}

enum ButtonType {
    Reroll, 
    Submenu, 
    Remove, 
    Move,
    Duplicate, 
    AiField, 
    AiReload
}

const ButtonsDef: Map<ButtonType, ButtonDef> = new Map([
    [ButtonType.Reroll, {
        image: `${process.env.PUBLIC_URL}/assets/icons/reroll.svg`,
        key: 'kReroll',
        name: 'Reroll',
        type: ButtonType.Reroll
    }],
    [ButtonType.Submenu, {
        image: `${process.env.PUBLIC_URL}/assets/icons/submenu.svg`,
        key: 'kSubmenu',
        name: 'Submenu',
        type: ButtonType.Submenu
    }],
    [ButtonType.Remove,{
        image: `${process.env.PUBLIC_URL}/assets/icons/remove.svg`,
        key: 'kRemove',
        name: 'Remove',
        type: ButtonType.Remove
    }],
    [ButtonType.Move, {
        image: `${process.env.PUBLIC_URL}/assets/icons/move.svg`,
        key: 'kMove',
        name: 'Move',
        type: ButtonType.Move
    }],
    [ButtonType.Duplicate, {
        image: `${process.env.PUBLIC_URL}/assets/icons/duplicate.svg`,
        key: 'kDuplicate',
        name: 'Duplicate',
        type: ButtonType.Duplicate
    }],
    [ButtonType.AiField, {
        image: `${process.env.PUBLIC_URL}/assets/icons/prompt.svg`,
        key: 'kAiField',
        name: 'AI Data',
        adminMode: true,
        type: ButtonType.AiField
    }],
    [ButtonType.AiReload, {
        image: `${process.env.PUBLIC_URL}/assets/icons/reloadai.svg`,
        key: 'kAiReload',
        name: 'Ai Reload',
        adminMode: true,
        type: ButtonType.AiReload
    }]
]);

export interface ButtonDef {
    key: string, 
    name: string, 
    image: string,
    type: ButtonType,
    submenu?: boolean,
    enabled?: boolean,
    adminMode?: boolean,
    onClick?(type: ButtonType): void,
    onMouseDown?(type: ButtonType, e:MouseEvent): void,
    onMouseMove?(e:MouseEvent): void
}

interface IProps {
    block: Block;
}

const ToolbarManipulator = (props: IProps) => {


    const [visible, setVisible] = useState( true )
    const [widget, setWidget] = useState<WidgetType>(WidgetType.Null)
    const [buttons, setButtons] = useState<ButtonDef[]>([])
    const [subButtons, setSubButtons] = useState<ButtonDef[]>([])

    const manipulatorInUse = useObservable(rxManipulatorInUse);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [width, setWidth] = useState(100);
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const { ref } = useElementClickedOutside( ()=> setSubmenuOpen(false) );

    let subscription: any = null;

    useEffect(() => {
        subscription = eventEmiter.subscribe({
          next: (e:any) => {
            if (e.type == 'view-on-scroll' || e.type == 'update-view') {
              update();
            }
          },
        });
        update();

        let buttons =  [ ButtonType.Duplicate, ButtonType.Move, ButtonType.Submenu];

        if(props.block.type === 'Text' || props.block.type === 'Image'){
            buttons.unshift( ButtonType.Reroll );
            
            if(isAdmin()){
                buttons.splice( buttons.length - 1, 0, ButtonType.AiField );
                
                if( props.block.type === 'Image'){
                    buttons.splice( buttons.length -2, 0, ButtonType.AiReload);
                }
            }
        }

        let subMenubutton = [ ButtonType.Remove];

        addButon(buttons, setButtons);
        addButon(subMenubutton, setSubButtons);
    
        return () => {
          subscription.unsubscribe();
        }
      }, []);

    const handleMouseDown = (type:ButtonType, e:MouseEvent) => {
        switch (type) {
            case ButtonType.Move:
                setVisible( false );
                const view = props.block.view
                const manipulator = view.overlay.manipulator;
                const localMouseMove = view.toLocal( {x: e.clientX, y: e.clientY })             
                manipulator.onMouseDownForHandler( localMouseMove, kBlockReorderHandler);

                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
        
                break;
        }
    }

    const handleMouseUp = (e:MouseEvent) =>{
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
        setVisible( true );
        const view = props.block.view
        const manipulator = view.overlay.manipulator;
        manipulator.onMouseUpForHandler( kBlockReorderHandler );

        update();
    }

    const handleMouseMove = (e:MouseEvent) => {
        const view = props.block.view
        const manipulator = view.overlay.manipulator;
        const movement = {x: e.movementX, y: e.movementY};
        const localMouseMove = view.toLocal( {x: e.clientX, y: e.clientY })
        manipulator.onMouseMoveForHandler( localMouseMove, movement,  kBlockReorderHandler);
    }

    const handleWidgetClose = () => {
        setWidget(WidgetType.Null);
        setVisible( true );
    }

    const handleButtonClick = (type:ButtonType) => {
        switch (type) {
            case ButtonType.Move:
                setVisible( true );
                break;

            case ButtonType.Duplicate:
                props.block.duplicateBlock();
                break;
        
            case ButtonType.Reroll:
                props.block.reroll();
                break;
            
            case ButtonType.Remove:
                props.block.removeFromParent();
                const view = props.block.view;
                const overlay = view.overlay;
                overlay.clearSelection();
                view.update();
                break;

            case ButtonType.Submenu:
                setSubmenuOpen( !submenuOpen );
                break;
            
            case ButtonType.AiField:
                setVisible( false );
                setWidget( WidgetType.AiField );
                // props.block.
                break;

            case ButtonType.AiReload:
                props.block.rerollWithAiPrompt();
                break;

            default:{
                console.log('WARNING: Unsuported action')
            }
        }
    }

    const addButon = ( types: ButtonType[], setter: any) => {
        let btns = [];
        for( const type of types){
            const btn = ButtonsDef.get(type);
            if(btn){
                btns.push( btn );
            }
            else{
                console.log('WARNING: ', btn, ' is not found')
            }
    
        }
        setter(btns);
        
    }

    const renderWidget = ( widget:WidgetType ) => {
        switch( widget){
            case WidgetType.Null:
                return <></>
            case WidgetType.AiField:{
                const aiFieldsName = props.block.view.gatherAiFieldsName();
                return <ToolbarWidgetAiFeild block={props.block} onClose={handleWidgetClose} aiFieldsName={aiFieldsName}/>
            }
        }
    }

    const update = () => {
        if(props.block){
            if(props.block.ref.current){
                const element = props.block.ref.current;
                const rect = element.getBoundingClientRect();

                setLeft( rect.x )
                
                if(rect.y < TOP_SCREEN_THRESHOLD){
                    setTop( rect.y + rect.height + TOOLBAR_BOTTOM_OFFSET );
                }
                else{
                    setTop( rect.y - TOOLBAR_TOP_OFFSET);
                }                
                
                setWidth( rect.width );
            }
        }
    }

    return (
        <div  className={s.container} style={{top: top, left: left, width: width, pointerEvents:'none'}}>
            {renderWidget( widget )}
            <div style={{display: visible?'block':'none'}}>
                <div ref={ ref } className={s.buttonContainer}>
                    { buttons?.map( button =>(
                        <ToolbarManipulatorButton 
                            enabled={!manipulatorInUse}
                            type={button.type}
                            key={button.key}
                            onClick={handleButtonClick}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            name={button.name} 
                            image={button.image}/>
                    ) )}
                </div>

                { submenuOpen && 
                <div className={s.submenu}>
                    {subButtons.map( button => (
                        <ToolbarManipulatorButton 
                        enabled={!manipulatorInUse}
                        type={button.type}
                        key={button.key}
                        onClick={handleButtonClick}
                        name={button.name} 
                        adminMode={button.adminMode}
                        submenu={true}
                        image={button.image}/>
                    ))}
                </div>}
            </div>
        </div>
    )
}

export default ToolbarManipulator;