import { AUTH_USER_TOKEN_KEY, CONSULT_URL, ENCRYPTION_KEY } from './Constants';
import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import { getSubToken } from 'utils/api';
import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import BadWordsFilter from 'bad-words';
import aes from 'crypto-js/aes';

const SUB_REFRESH_TOKEN_NAME = 'SUB_REFRESH_TOKEN_NAME';
const SUB_TOKEN_NAME = 'SUB_TOKEN_NAME';

export const validateToken = (token) => {
  return new Promise(async (resolve, reject) => {
    if (!token) {
      reject("Token is Empty");
    }
    try {
      let pureToken = token;
      if (token.includes('Bearer')) {
        const arr = token.split(' ');
        pureToken = arr[1];
      }
      const decodedJwt = jwtDecode(pureToken);

      if(decodedJwt.exp >= Date.now() / 1000){
        resolve(token);
      }
      else{
        refreshToken()
        .then( newToken => resolve( newToken ))
        .catch( error => reject( error ))
      }
    } catch (e) {
      return false;
    }
  })
};

export const username = (token) => {
  if (!token) {
    return '';
  }
  try {
    const decodedJwt = jwtDecode(token);
    return decodedJwt.username;
  } catch (e) {
    return '';
  }
};

export const refreshToken = ()=>{
  return new Promise(async (resolve, reject) => {
    const subAccountToken = getSubToken();
    try {
      if (subAccountToken) {
        const oldRefreshToken = getSubRefreshToken();
          const { token, refreshToken } = await graphQlCall({
            queryTemplateObject: QUERIES.REFRESH_TOKEN,
            values: {
              refreshToken: oldRefreshToken
            },
          });
          storeSubToken(token);
          storeSubRefreshToken(refreshToken);
          resolve(token);
      } else {
        let user = await Auth.currentAuthenticatedUser();
        let refreshToken = user.getSignInUserSession().getRefreshToken();      
        user.refreshSession(refreshToken, (refErr, refSession) => {
          if (refErr) {
            console.error(refErr);
            reject(refErr);
          }
          else{
            localStorage.setItem(
              AUTH_USER_TOKEN_KEY,
              refSession.accessToken.jwtToken
            );
            resolve(refSession.accessToken.jwtToken);
          }
        });
      }
    } catch (error) {
      console.error('refresh token error', error);
      // window.open(`${CONSULT_URL}/console/login`, '_self');
    }
  });
}

export const storeSubToken = (token) => {
  localStorage.setItem(SUB_TOKEN_NAME, token);
}

export const getSubRefreshToken = () => {
  const token = localStorage.getItem(SUB_REFRESH_TOKEN_NAME);
  if (!token) {
    throw new Error('sub refresh token not exists');
  }
  return token;
}

export const storeSubRefreshToken = (refreshToken) => {
  localStorage.setItem(SUB_REFRESH_TOKEN_NAME, refreshToken);
}

export const initiateBadWordsFilter = () => {
  const badWordFilter = new BadWordsFilter({ placeHolder: ' ' });
  const removeWords = [
    "God",
    "Allah",
    "Yahweh",
    "Jehovah",
    "Jesus",
    "Christ",
    "Buddha",
    "Krishna",
    "Vishnu",
    "Shiva",
    "Ganesha",
    "Brahma",
    "Muhammad",
    "Guru Nanak",
    "Moses",
    "Abraham",
    "Mary",
    "Holy Spirit",
    "Angel",
    "Prophet",
    "Messiah",
    "Divine",
    "Sacred",
    "Faith",
    "Prayer",
    "Worship",
    "Spirituality",
    "Religion",
    "Salvation",
    "Enlightenment",
  ];

  badWordFilter.removeWords(...removeWords);

  return badWordFilter;
}

export const encrypt = (text) => {
  return aes.encrypt(text, ENCRYPTION_KEY).toString();
}

export const decrypt = (text) => {
  return aes.decrypt(text, ENCRYPTION_KEY).toString();
}

// Key generator for entityMap items
var idCounter = -1;
function generateUniqueKey() {
  idCounter++;
  return idCounter;
}

export const textParserToDraft = ( text, inlineStyles, parentBlockType ) => {
  // Define regular expression to find <center></center> tags
  var centerTagRegex = /<center>(.*?)<\/center>/g;
  // var boldMarkerRegex = /\*\*(.*?)\*\*/g; 

  var allRegex = new RegExp([
    centerTagRegex.source, 
    // boldMarkerRegex.source
  ].join('|'), 'g');

  // Split text into blocks based on <center></center> and ** tags
  var blocks = [];
  var lastIndex = 0;
  var match;
  while ((match = allRegex.exec(text)) !== null) {
      // Add text before the match (if any) as a block
      if (match.index > lastIndex) {
          blocks.push({
            type:'normal',
            text: text.substring(lastIndex, match.index)
          });
      }
      // Add the content of the <center></center> tag as a block
      if(match[1] !== undefined){
        blocks.push({
          type:'center',
          text: match[1]
        });
      }
      
      // Update lastIndex to the end of the match
      lastIndex = match.index + match[0].length;
  }
  // Add remaining text (after the last match) as a block
  if (lastIndex < text.length) {
      blocks.push({
        type:'normal',
        text: text.substring(lastIndex)
      });
  }

  let draftBlocks = [];
  for( const block of blocks){
    let draftBlock = {
      key : generateUniqueKey(),
      text : block.text, 
      type : block.type == 'center' ? 'align-center-item' : parentBlockType,
      depth: 0,
      inlineStyleRanges: inlineStyles
        .filter((style) => style.offset == 0)
        .map((style) => ({
          ...style,
          length: block.text.length,
        })),
    }

    if(block.type === 'center'){
      draftBlock.inlineStyleRanges.push({
        offset : 0,
        length: block.text.length, 
        style: 'BOLD'
      })
    }
    draftBlocks.push(draftBlock);
  }

  return  { blocks: draftBlocks, entityMap: {} }
}