import { rxProducts } from '../rxState';
import { graphQlCall } from '../../graphql/utils';
import QUERIES from '../../graphql/queries';

export const fetchProducts = async (state) => {
    try {
        const products = await graphQlCall({
            queryTemplateObject: QUERIES.FETCH_PRODUCTS,
            headerType: "USER-AUTH",
        });
        const productsValue = rxProducts.value;
        rxProducts.next(productsValue.concat( products ));       
    } catch (error) {
        console.log('error:', error)
    }
}