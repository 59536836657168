import { rxPages, rxNavigation } from '../rxState';

export const duplicatePage = (state, { name, newName }) => {
  const pageData = state.pages[name];

  let navigation = [...state.navigation];
  navigation.push({
    name: newName,
    url: newName,
  });

  let pages = { ...state.pages };
  pages[newName] = pageData;

  rxNavigation.next(navigation);
  rxPages.next(pages);
};
