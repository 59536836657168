import React from 'react';
import { ReactComponent as CloseSvg } from '../../Assets/closeModal.svg';

import s from './PopupWrapper.module.scss';

interface IProps {
  children: React.ReactNode;
  wide?: boolean;
  onClose?: () => void;
  grayBackgroundColor?: boolean;
  width?: string;
  className?: string;
}

const PopUpWrapper = (props: IProps) => {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div
      className={s.back}
      onClick={(e: any) => e.target.className === s.back && handleClose()}
    >
      <div
        className={`${props.wide ? s.wideModal : s.modal} ${
          props.className || ''
        }`}
        style={props.width ? { width: props.width } : {}}
      >
        {props.onClose && (
          <CloseSvg className={s.closeIcon} onClick={handleClose} />
        )}
        {props.children}
      </div>
    </div>
  );
};

export default PopUpWrapper;
