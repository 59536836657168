import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SwitchSmall from '../Common/Switches/SwitchSmall/SwitchSmall';
import { eventEmiter, rxDuplicateSection, rxRemoveSection } from 'rx/rxState';
import { ReactComponent as DuplicateIcon } from 'Assets/section-duplicate.svg';
import { ReactComponent as ArrowBottom } from 'Assets/arrowOverlayBottom.svg';
import { ReactComponent as DeleteIcon } from 'Assets/section-delete.svg';
import { ReactComponent as ArrowUp } from 'Assets/arrowOverlayUp.svg';
import { ReactComponent as Prompt } from 'Assets/prompt.svg';
import { ReactComponent as Reload } from 'Assets/reloadai.svg';
import RerollComponent from 'Components/AIRerollImage/RerollComponent';
import ToolbarWidgetAiFeild from './ToolbarWidgetAiField';
import { isAdmin } from '../../utils/api';

const WrapperStyle = styled.div.attrs((props) => ({
  style: {
    top: props.top + 10,
    right: props.right > 0 ? props.right + 20 : 20,
  },
}))`
  position: absolute;
  display: flex;
  column-gap: 10px;
`;

const ControlsStyle = styled.div`
  display: flex;

  .borderRight {
    border-radius: 0px 4px 4px 0px;
  }
  .borderLeft {
    border-radius: 4px 0px 0px 4px;
  }
  .marginRight {
    margin-right: 7px;
  }
`;

const ControlsButtonStyle = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  svg {
    fill: #d0d0d0;
  }
  :hover {
    background-color: #3e3e3e;
    svg {
      fill: #ffffff;
    }
  }
`;

const ControlsVisibilityList = styled.div`
  background-color: white;
  position: absolute;
  top: 45px;
  transform: translate(calc(-100% + 40px), 0px);
`;

const ControlsVisibilityItem = styled.div`
  padding: 5px;
  display: flex;
  border-top: 1px solid #eef1f4;

  .block-name {
    color: black;
    padding: 3px;
  }
  .block-switch {
    padding: 3px;
  }
`;

const ToolbarSection = (props) => {
  const [displayVisibilityControl, setDisplayVisibilityControl] =
    useState(false);
  const [top, setTop] = useState(0);
  const [right, setRight] = useState(0);
  const [counter, setCounter] = useState(0); //Dirty hack to make whole thing update
  const [visible, setVisible] = useState(false);
  const [promptVisible, setPromptVisible] = useState(false);

  const originalRef = useRef(null);

  useEffect(() => {
    const subscription = eventEmiter.subscribe({
      next: (e) => {
        if (e.type == 'view-on-scroll') {
          update();
        }
      },
    });
    update();
    // saveOriginalImage();

    const handleResize = () => {
      const bookCanvas = document.getElementById('view-canvas-book');
      if (bookCanvas) {
        const view = document.getElementById('view');
        if (view) {
          setRight(window.innerWidth - view.getBoundingClientRect().right);
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      subscription.unsubscribe();
      restoreOriginalImage();
    };
  }, []);

  const update = () => {
    if (props.block) {
      setVisible(!props.block.isDragging);
      if (props.block.ref.current) {
        const element = props.block.ref.current;
        const rect = element.getBoundingClientRect();

        let y = rect.y;

        if (y < 60) {
          // y = 60;
        }
        // console.log(y)
        setTop(y);
      }
    }
  };

  const handleVisiblityChange = (block, value) => {
    block.setVisible(value);
    setCounter(counter + 1);
  };

  const handleVisibilityControl = () => {
    setDisplayVisibilityControl(!displayVisibilityControl);
  };

  const visibilityList = () => {
    return (
      <ControlsVisibilityList>
        {props.visibilityBlocks.map((block) => (
          <ControlsVisibilityItem key={block.id}>
            <div className="block-name">
              {block.name ? block.name : block.type}
            </div>
            <div style={{ flexGrow: '4' }}></div>
            <div className="block-switch">
              <SwitchSmall
                value={block.isVisible}
                block={block}
                onChange={handleVisiblityChange}
              />
            </div>
          </ControlsVisibilityItem>
        ))}
      </ControlsVisibilityList>
    );
  };

  const handleDelete = (e) => {
    const block = props.block;
    const blockIndex = block.view.getBlockIndex(block);
    rxRemoveSection.next({ index: blockIndex });

    setDisplayVisibilityControl(false);
  };

  const handleDuplicate = (e) => {
    const block = props.block;
    const blockIndex = block.view.getBlockIndex(block);
    rxDuplicateSection.next({ index: blockIndex });

    setDisplayVisibilityControl(false);
  };

  const handleMoveSectionWithArrow = (direction) => {
    const view = props.block.view;
    const index = view.blocks.indexOf(props.block);
    view.reorderSection(index, index + direction);
    // props.block.view.overlay.moveSectionWithArrow(direction);
    setDisplayVisibilityControl(false);
  };

  const handleChangeImage = (url) => {
    if (props.block && props.block.image) {
      props.block.image.value = url;
      props.block.view.update();
    }
  };

  const saveOriginalImage = () => {
    if (!props.block || !props.block.image) {
      return;
    }
    originalRef.current = props.block.image.value;
  };

  const restoreOriginalImage = () => {
    if (!props.block || !props.block.image || !originalRef.current) {
      return;
    }
    props.block.image.value = originalRef.current;
    originalRef.current = null;
    props.block.view.update();
  };

  const handleSubmitImage = (url) => {
    originalRef.current = url;
  };

  if (visible)
    return (
      <WrapperStyle top={top} right={right}>
        {props.block.image && (
          <>
            <RerollComponent
              onChange={handleChangeImage}
              onStart={saveOriginalImage}
              onCancel={restoreOriginalImage}
              onSubmit={handleSubmitImage}
            />
            {isAdmin() && (
              <>
                {promptVisible ? (
                  <ToolbarWidgetAiFeild
                    block={props.block}
                    onClose={() => setPromptVisible(false)}
                    aiFieldsName={props.block?.view?.gatherAiFieldsName()}
                  />
                ) : (
                  <ControlsStyle>
                    <ControlsButtonStyle
                      onClick={() => {
                        setPromptVisible(true);
                      }}
                      className="borderLeft"
                    >
                      <Prompt style={{ margin: 'auto' }} />
                    </ControlsButtonStyle>
                    <ControlsButtonStyle
                      onClick={() => props.block.rerollWithAiPrompt()}
                      className="borderRight"
                    >
                      <Reload style={{ margin: 'auto' }} />
                    </ControlsButtonStyle>
                  </ControlsStyle>
                )}
              </>
            )}
          </>
        )}

        <ControlsStyle>
          <ControlsButtonStyle
            onClick={() => {
              handleMoveSectionWithArrow(-1); //UP
            }}
            className="borderLeft"
          >
            <ArrowUp style={{ margin: 'auto' }} />
          </ControlsButtonStyle>
          <ControlsButtonStyle
            onClick={() => {
              handleMoveSectionWithArrow(+1); //DOWN
            }}
            className="borderRight"
          >
            <ArrowBottom style={{ margin: 'auto' }} />
          </ControlsButtonStyle>
        </ControlsStyle>

        <ControlsStyle>
          <ControlsButtonStyle onClick={handleDuplicate} className="borderLeft">
            <DuplicateIcon style={{ margin: 'auto' }} />
          </ControlsButtonStyle>

          <ControlsButtonStyle onClick={handleDelete} className="borderRight">
            <DeleteIcon style={{ margin: 'auto' }} />
          </ControlsButtonStyle>

          {displayVisibilityControl ? visibilityList() : null}
        </ControlsStyle>
      </WrapperStyle>
    );

  return null;
};

export default ToolbarSection;
