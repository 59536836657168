export const REORDER_CHAPTERS_MUTATION = {
  operationName: 'reorderChapters',
  query: `mutation reorderChapters(
      <KEYS>
    ) {
      reorderChapters(
        <VALUES>
      ) {
          title
          text
          comments
      }
    }`,
};
