import React from 'react';
import s from './SaveSpinner.module.scss';
import spinner from '../../../Assets/spinner2.svg';
import spinnerLogo from '../../../Assets/spinnerLogo.svg';
import Spinner from '../Spinner/Spinner';

function SaveSpinner() {
  return (
    <div className={s.container}>
      <div>
        <Spinner size={110} />
        <div>Saving page...</div>
      </div>
    </div>
  );
}

export default SaveSpinner;
