import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './sectionsStyle.scss';

import App from './App';
// import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

import Immutable from 'immutable';
import * as Sentry from "@sentry/react";
import { SENTRY_DNS } from 'Constants';

Immutable.Iterable.noLengthWarning = true;


Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


//TODO: need to CLEAN THIS MESS eventually 
let urlComponents = window.location.pathname.split('/');
console.log(urlComponents);
let editMode = false;
let userId = '';
let productId = '';
let page = '';
let productMode = false;
if (['edit', 'view', 'login', 'generate', 'signup', 'signup2', 'pricing', 'special-discount', 'no-trial-special', 'book', 'book-editor', 'html', 'test', 'ai-template', 'ai-page'].includes(urlComponents[2])) {
  //EDITOR MODE
  editMode = true;
  userId = urlComponents[2];
  productId = urlComponents[3];
  page = urlComponents[4];
}
//LIVE MODE Preview
else if (urlComponents.length === 5 && urlComponents[1] === 'edit') {
  // initSentry();
  productId = urlComponents[3];
  page = urlComponents[4];
}
//LIVE MODE Production
else {
  // initSentry();
  editMode = false;
  productMode = true;
}

ReactDOM.hydrate(
    <App
      editMode={editMode}
      page={page}
      productId={productId}
      userId={userId}
      productMode={productMode}
    />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
