import React, { useEffect, useRef, useState } from 'react';
import s from './mobileSectionsPopup.module.scss';
import { ReactComponent as AddForm } from '../../Assets/AddForm.svg';
import { ReactComponent as AddHeader } from '../../Assets/AddHeader.svg';
import { ReactComponent as AddImage } from '../../Assets/AddImage.svg';
import { ReactComponent as AddText } from '../../Assets/AddText.svg';
import { ReactComponent as AddTools } from '../../Assets/AddTools.svg';
import { ReactComponent as AddVideo } from '../../Assets/AddVideo.svg';
import { ReactComponent as AddWidget } from '../../Assets/AddWidget.svg';

import { useObservable } from 'utils/UseObservable';
import {
  rxBlockIndexForSectionsPopup,
  rxSideMenu,
  rxCreationData,
} from 'rx/rxState';
import Spinner from 'Components/Common/Spinner/Spinner';
import Sections from './Sections/Sections';

const menuItemsSvg = {
  Header: <AddHeader />,
  Image: <AddImage />,
  Text: <AddText />,
  Video: <AddVideo />,
  Forms: <AddForm />,
  Tools: <AddTools />,
  Widgets: <AddWidget />,
  'AI-Custom': null,
  'New Sections': null,
};

function MobileSectionsPopup(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectSectionIndex, setSelectSectionIndex] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const menuItems = useObservable(rxSideMenu);
  const sectionsContent = useRef();
  const generateSectionsBlock = () => {
    if (activeMenu === null) {
      return;
    }
    const subMenu = menuItems[activeMenu].items;
    if (!subMenu || !subMenu.length) {
      return;
    }

    const handleSectionClick = (sectionData) => {
      rxCreationData.next({
        data: sectionData,
        blockType: 'section',
        index: selectSectionIndex,
      });
      handleClose(true);
    };

    function divideArray(array) {
      const result = [];
      let currentSection = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].type === 'devider') {
          currentSection = [];
          result.push({ sections: currentSection, devider: array[i].label });
        } else {
          currentSection.push(array[i]);
        }
      }
      return result;
    }
    const subMenuDivideArr = divideArray(subMenu);
    return (
      <Sections
        sections={subMenuDivideArr}
        handleSectionClick={handleSectionClick}
      />
    );
  };
  const handleClickMenu = (i) => {
    sectionsContent.current.scrollTop = 0;
    if (i === activeMenu) {
      setActiveMenu(null);
      return;
    }
    setActiveMenu(i);
  };
  const handleClose = (forcedClose, e) => {
    if (e && e.target.className === s.container) {
      rxBlockIndexForSectionsPopup.next(-1);
      setSelectSectionIndex(null);
      setActiveMenu(null);
    }
    if (forcedClose) {
      rxBlockIndexForSectionsPopup.next(-1);
      setSelectSectionIndex(null);
      setActiveMenu(null);
    }
  };

  useEffect(() => {
    const subscription = rxBlockIndexForSectionsPopup.subscribe(
      (sectionIndex) => {
        if (sectionIndex >= 0) {
          setIsOpen(true);
          setSelectSectionIndex(sectionIndex);
        } else {
          setIsOpen(false);
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  if (menuItems && !!menuItems.length) {
    return (
      <div className={s.container} onClick={(e) => handleClose(false, e)}>
        {menuItems && (
          <div className={s.menuItemsBlock}>
            {menuItems.map((menuItem, i) => {
              return (
                <div
                  className={s.menuItemBlock}
                  onClick={() => handleClickMenu(i)}
                  key={i}
                >
                  <div
                    className={
                      i === activeMenu
                        ? `${s.menuItemBlockInner} ${s.active}`
                        : s.menuItemBlockInner
                    }
                  >
                    {menuItemsSvg[menuItem.name]
                      ? menuItemsSvg[menuItem.name]
                      : null}
                    <div>{menuItem.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div
          className={
            activeMenu !== null
              ? `${s.sectionsContent} ${s.active}`
              : s.sectionsContent
          }
          ref={sectionsContent}
        >
          {generateSectionsBlock()}
        </div>
      </div>
    );
  } else
    return (
      <div className={s.containerForSpinner}>
        <Spinner size={250} />
      </div>
    );
}

export default MobileSectionsPopup;
