import s from './RerollAiButton.module.scss';

interface IProps {
  onSubmit: () => void;
}

const RerollAiButton = ({ onSubmit }: IProps) => {
  return (
    <button className={s.button} onClick={onSubmit}>
      <span>AI</span>
    </button>
  );
};

export default RerollAiButton;
