import { encrypt, decrypt } from 'Utils';
import QUERIES from "graphql/queries";
import { graphQlCall } from "graphql/utils";

export class UserInfo {
  private static instance: UserInfo;
  private role:string = 'user';
  private name:string = '';
  private email:string = '';
  private stripeClientId:string = '';
  private scopes: string[] = [];
  private plans: string[] = [];
  private aiCredits: number = 0;
  private initiated = false;
  private readonly STORAGE_NAME = 'USER';
  private expiration = 0;
  private expirationPeriod = 1000; //1000 * 60 * 60; // one hour;
  private paymentMethod:string = '';

  static getInstance() {
    if (!UserInfo.instance) {
      UserInfo.instance = new UserInfo();
    }
    return UserInfo.instance;
  }

  private async initiate() {
    const data = await graphQlCall({
      queryTemplateObject: QUERIES.GET_CURRENT_USER_QUERY,
      headerType: 'USER-AUTH',
    })

    // console.log('data:', data);

    this.role = data.role as string;
    this.name = data.name as string;
    this.email = data.email as string;
    this.paymentMethod = data.paymentMethod as string;
    this.stripeClientId = data.stripeClientId as string;
    this.scopes = data.scopes as string[];
    this.aiCredits = data.aiCredits;
    this.plans = data.plans;
    this.initiated = true;
    const date = new Date();
    this.expiration = date.valueOf() + this.expirationPeriod;

    const payload = {
      role: this.role,
      name: this.name,
      email: this.email,
      stripeClientId: this.stripeClientId,
      scopes: this.scopes,
      expiration: this.expiration,
      paymentMethod: this.paymentMethod,
      plans: this.plans,
    }
    this.storeToStorage(payload);
  }

  hasInitiated() {
    return this.initiated;
  }

  hasActual() {
    const date = new Date();
    return this,this.expiration > date.valueOf();
  }

  private generateInfo() {
    return {
      role: this.role,
      name: this.name,
      email: this.email,
      stripeClientId: this.stripeClientId,
      scopes: this.scopes,
      paymentMethod: this.paymentMethod,
      plans: this.plans,
      aiCredits: this.aiCredits
    }
  }

  async getInfo() {
    if (this.initiated && this.hasActual())  {
      return this.generateInfo();
    }

    try {
      const storedData = this.getFromStorage();
      this.expiration = storedData.expiration;
      if (this.hasActual()) {
        this.role = storedData.role as string;
        this.name = storedData.name as string;
        this.email = storedData.email as string;
        this.stripeClientId = storedData.stripeClientId as string;
        this.scopes = storedData.scopes as string[];
        this.aiCredits = storedData.aiCredits as number;
        this.initiated = true;
        return this.generateInfo();
      }
      throw new Error('stored data not actual');
    } catch (_e) {
      await this.initiate();
      return this.generateInfo();
    }
  }

  private storeToStorage(data: any) {
    localStorage.setItem(this.STORAGE_NAME, encrypt(JSON.stringify(data)));
  }

  private getFromStorage() {
    const str = localStorage.getItem(this.STORAGE_NAME);
    if (!str) {
      throw new Error('local storage not set');
    }
    this.initiated = true;
    return JSON.parse(decrypt(str));
  }
}