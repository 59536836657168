export const ADD_CHAPTER_MUTATION = {
  operationName: 'addChapter',
  query: `mutation addChapter(
      <KEYS>
    ) {
      addChapter(
        <VALUES>
      ) {
        title
        text
        comments
      }
    }`,
};
