import { rxCurrentPage, rxBlocks, rxPages, rxNavigation } from '../rxState';

export const loadPage = (state, { page }) => {
  const { pages, navigation } = state;
  let pageData = pages[page];

  if (!pageData) {
    pageData = [];
    let newPages = { ...pages };
    newPages[page] = pageData;

    let newNavigation = [...navigation];
    newNavigation.push({
      name: page,
      url: page,
    });
    rxNavigation.next(newNavigation);
    rxPages.next(newPages);
  }

  rxBlocks.next(pageData);
  rxCurrentPage.next(page);
};
