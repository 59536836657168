import React, { useEffect, useState } from 'react';
import s from './Slider.module.scss';
import { ReactComponent as Arrow } from '../../../Assets/arrowForSlider.svg';
import FakeLoading from 'Components/Common/FakeLoading/FakeLoading';

interface SliderProps {
  slides: string[];
  loading?: boolean;
  textForImg?: string;
  setCurrentImg?: (img: string) => void;
}

const Slider: React.FC<SliderProps> = ({
  slides,
  loading,
  textForImg,
  setCurrentImg,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (setCurrentImg) {
      setCurrentImg(slides[currentSlide]);
    }
  }, [currentSlide]);
  const goToSlide = (slideIndex: number) => {
    setCurrentSlide(slideIndex);
  };

  const goToPrevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };

  const goToNextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };
  if (!loading) {
    return (
      <div className={s.sliderContainer}>
        <div
          className={s.slider}
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div key={index} className={s.slide}>
              <div className={s.slideImageWrapper}>
                <img src={slide} alt={`Slide ${index + 1}`} />
                {/* {textForImg && <div className={s.slideText}>{textForImg}</div>} */}
              </div>
            </div>
          ))}
        </div>
          <div className={s.arrow + ' ' + s.arrowLeft} onClick={goToPrevSlide}>
            <Arrow />
          </div>
          <div className={s.arrow + ' ' + s.arrowRight} onClick={goToNextSlide}>
            <Arrow />
          </div>
        <div className={s.dots}>
          {slides.map((_, index) => (
            <span
              key={index}
              className={`${s.dot} ${currentSlide === index ? s.active : ''}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={s.sliderImgLoading}> 
        <FakeLoading timeForOnePercent={1000} textForLoading={null}/>
        {textForImg && <div className={s.slideText}>{textForImg}</div>}
      </div>
    );
  }
};

export default Slider;
