import { rxPages, rxNavigation } from '../rxState';

export const addPage = (state, { name }) => {
  let navigation = [...state.navigation];
  navigation.push({
    name: name,
    url: name,
  });

  let pages = { ...state.pages };
  pages[name] = [];

  rxNavigation.next(navigation);
  rxPages.next(pages);
};
