import { useState, useEffect } from 'react';
import { Observable } from 'rxjs';

/**
 * @param {Observable} observable
 */
export const useObservable = (observable) => {
  const [state, setState] = useState();

  useEffect(() => {
    const sub = observable.subscribe(setState);
    return () => sub.unsubscribe();
  }, [observable]);

  return state;
};
