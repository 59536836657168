export const GET_USER_DETAILS_QUERY = {
  operationName: "getUserDetails",
  query:
    `query getUserDetails($owner: String!) {
      getUserDetails(owner: $owner) {
        _id
        name
        owner
        email
        role
        subdomain
        stripePublicKey
        stripeSecretKey
        stripeClientId
        active
      }
    }`,
}