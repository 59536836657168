import React from 'react';
import { CONSULT_URL } from 'Constants';
import { rxBookNeedSave } from 'rx/rxState';
import { ReactComponent as HomeIcon } from '../../../Assets/Home.svg';
import { TOOLBAR_ID } from '../../Toolbar/ToolbarPanel';
import ToolbarBlocks from '../../Toolbar/ToolbarBlocks/ToolbarBlocks';

import s from './Toolbar.module.scss';

interface ToolbarProps {
  showOnlyHomeBtn?: boolean;
}

const Toolbar: React.FC<ToolbarProps> = ({ showOnlyHomeBtn }) => {
  const handleRedirectToHomePage = () => {
    const homeLink = `${CONSULT_URL}/console/books`;
    window.open(homeLink, '_self');
  };
  const onSaveClick = () => {
    rxBookNeedSave.next(true);
  };

  if (showOnlyHomeBtn) {
    return (
      <div className={s.toolbarWrapper} id={TOOLBAR_ID}>
        <div className={s.homeBlock} onClick={handleRedirectToHomePage}>
          <HomeIcon />
        </div>
      </div>
    );
  } else
    return (
      <div className={s.toolbarWrapper} id={TOOLBAR_ID}>
        <div className={s.homeBlock} onClick={handleRedirectToHomePage}>
          <HomeIcon />
        </div>
        <ToolbarBlocks />
        <div className={s.saveButton} onClick={onSaveClick}>
          Save
        </div>
      </div>
    );
};
export default Toolbar;
