export const CREATE_PAGE_MUTATION = {
  operationName: "createPage",
  query:
    `mutation createPage(
      $name: String!
      $data: String!
      $funnelId: String!
    ) {
      createPage(
        name: $name
        data: $data
        funnelId: $funnelId
      ) {
        _id
      }
    }`,
}