import React, { FC } from 'react';
import styles from './dropdownBox.module.scss';
import clsx from 'clsx';

interface DropdownBoxProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  componentRef: any;
  pointerEvents: boolean;
  shift?: string;
  height?: number;
}

const DropdownBox: FC<DropdownBoxProps> = ({
  children,
  isOpen,
  onClose,
  componentRef,
  pointerEvents,
  shift,
  height,
}) => {
  const dropdownBoxPosition = {
    x: 0,
    y: 0,
  };

  if (null !== componentRef.current) {
    const blockPosition = componentRef.current.getBoundingClientRect();
    dropdownBoxPosition.x = blockPosition.right;
    dropdownBoxPosition.y = blockPosition.bottom;

    if (height) {
      const windowHeight = window.innerHeight;
      const posDifference = windowHeight - (blockPosition.bottom + height);
      if (posDifference < 0) {
        dropdownBoxPosition.y = dropdownBoxPosition.y + posDifference;
      }
    }
  }

  const pointerEventsStyle = {} as any;
  if (!pointerEvents) {
    pointerEventsStyle.pointerEvents = 'none';
  }

  const dropdownBoxStyle = {} as any;
  dropdownBoxStyle.left = dropdownBoxPosition.x;
  dropdownBoxStyle.top = dropdownBoxPosition.y;

  if (isOpen) {
    return (
      <div
        className={clsx(styles.dropdownBoxWrapper, {
          [styles.dropdownBoxActive]: isOpen,
        })}
        onClick={onClose}
        style={pointerEventsStyle}
      >
        <div
          className={styles.dropdownBoxCard}
          onClick={(e) => e.stopPropagation()}
          style={dropdownBoxStyle}
        >
          {children}
        </div>
      </div>
    );
  }

  return null;
};

export default DropdownBox;
